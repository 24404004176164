.totalSalesDiv {
    min-width: 300px;
    max-width: 5rem;
    min-height: 120px;
    max-height: 6rem;
}

.totalSalesLabel {
    background-color: #2E3E80;
    color: #fff;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    border-radius: 5px 5px 0px 0px;
}

.salesValue {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 32px;
    padding: 5%;
    font-weight: 800;
    background-color: #E1F0FF;
    color: #222222;
    border-radius: 0px 0px 5px 5px;
}

@media (max-width: 1001px) and (max-width:1200px) {
    .totalSalesDiv {
        min-height: 120px;
        max-height: 6rem;
        max-width: 300px;
        min-width: 300px;
    }
}

@media (max-width: 767px) and (max-width:1100px) {
    .totalSalesDiv {
        min-height: 100px;
        max-width: 4rem;
        min-width: 250px;
    }
    
}
@media (max-width: 480px) {
    .totalSalesDiv {
        min-height: 4.5rem;
        max-width: 4rem;
        min-width: 15rem;
    }
    
}