.lblLatestTrans {
    text-align: left;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 14px;
}

.lblMachineHealth {
    text-align: left;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 14px;
}

.lblProductTable {
    text-align: left;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 14px;
}

.lblMachineTable {
    text-align: left;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 14px;
}

.macTabDiv {
    min-width: 100%;
    max-width: 45%;
    margin-top: 30px;
}

.proTableDiv {
    min-width: 100%;
    max-width: 45%;
    margin-top: 15px;
}

@media (max-width:1023px) {
    .proTableDiv {
        min-width: 100%;
    }
    .macTabDiv {
        min-width: 100%;       
    }
}

@media (max-width: 768px) {
    .proTableDiv {
        min-width: 100%;
    }
    .macTabDiv {
        min-width: 100%;
        
    }
}

