.container {
    min-width: 100vw;
    min-height: 100vh;
    margin: 0;
    padding: 0;
}

.machineTitle {
    width: 100%;
}

.area {
    width: 45%;
    border-right: 1px solid lightgrey;
    padding: 1rem;
}

.machineArea {
    display: flex;
    width: 100%;
    min-height: 0rem;
    max-height: 150rem;
    padding: 0;
    margin: 0;
}

.machineAreas {
    width: 100%;
    min-height: 0rem;
    max-height: 150rem;
    padding: 0;
    margin: 0;
}

.machineTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.handleSaleTrans {
    min-width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 30px 0px;
    flex-wrap: wrap;
}

.handleSaleCharts {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    min-width: 70%;
    max-width: 100%;
}

.realtimeDiv {
    width: 40%;
    padding: 1rem;
    display: flex;
    justify-content: flex-start;
}

.machineArea .area:last-child {
    border-right: none;
}

.rdrDefinedRangesWrapper {
    display: none;
}

#dropdown-basic {
    min-width: 152px;
    min-height: 40px;
}

.custom-dropdown .btn-primary {
    border: 1px solid lightgray;
    color: black;
    background-color: transparent;
    padding: 0;
}

.custom-dropdown .btn-primary:focus,
.custom-dropdown .btn-primary:active,
.custom-dropdown .btn-primary.show {
    background-color: transparent !important;
    border-color: lightgray !important;
    box-shadow: none !important;  /* Removes Bootstrap focus outline */
}

.custom-dropdown .btn-primary:focus,
.custom-dropdown .btn-primary:active,
.custom-dropdown .btn-primary.show {
    background-color: transparent !important;
    border-color: lightgray !important;
    box-shadow: none !important;  /* Removes Bootstrap focus outline */
}

#dropdown-basic::after {
    display: none !important; /* Hides the default Bootstrap arrow */
}

@media (min-width:1200px) {
    .machineArea {
        min-height: 0rem;
    }

    .handleSaleTrans {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        min-width: 100%;
    }
    .tab-content {
        min-width: 80%;
    }
}

@media (max-width: 1023px) {
    .area {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        border: none;
        padding: 0rem 1rem;
        justify-content: center;
    }
    .machineTitle {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
    .machineArea {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }
    .handleSaleTrans {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        min-width: 100%;
        min-height: 15rem;
    }
    .handleSaleCharts {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        min-width: 70%;
        max-width: 100%;
    }
    .realtimeArea {
        width: 90%;
        padding: 0rem 1rem;
    }
}

@media (max-width: 768px) {
    .machineArea {
        min-height: 90rem;
    }
}

@media (max-width: 480px) {
    .machineArea {
        min-height: 90rem;
        padding-top: 7rem;
    }
    .handleSaleTrans {
        min-width: 100%;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }
    .handleSaleCharts {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left:0px;
    }
    .realtimeDiv {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
}

/* Extra-small screens (max-width 479px) */
@media (max-width: 479px) {
    .machineArea {
        display: flex;
        flex-direction: column; /* Stack children vertically */
        align-items: stretch; /* Ensure full-width children */
        gap: 1rem; /* Add spacing between .area and .realtimeDiv */
        flex-wrap: nowrap;
        padding-top: 10rem;
    }

    .area {
        width: 100%;
    }

    .realtimeDiv {
        width: 100%;
    }
}

@media (max-width: 320px) {
    .content-container {
        padding: 0;
    }
    .area {
        padding: 0;
    }
    .machineArea {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        padding-top: 20rem;
    }
    .area {
        width: 100%;
        border: none;
    }

    .machineTitle {
        display: flex;
        flex-direction: column;
    }
    
    .handleSaleCharts {
        display: flex;
        flex-direction: column;
        min-width: 20%;
        max-width: 95%;
        padding: 0px 10px;
    }
    
    .realtimeDiv {
        width: 100%;
    }
}