.navbar-container {
    position: fixed;
    width: 100%;
    height: 60px; /* Adjust according to your design */
    background-color: white; /* Optional */
    z-index: 1000; /* Ensure it's above other elements */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow */
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--white);
    color: var(--black);
    padding: 5px 20px 10px 20px;
    border-bottom: 1px solid var(--lightGray);
    height: 64px;
    min-width: 100%;
    max-width: 100%;
}

.navbar-logo {
    display: flex;
    justify-content: flex-start;
}

.navbar-toggle {
    display: none;
    font-size: 1.5rem;
    background: none;
    border: none;
    color: var(--white);
    cursor: pointer;
}

.account-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 10%;
}

.notifications {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.notification-badge {
    color: var(--white);
    background-color: var(--red);
    border-radius: 50%;
    min-width: 1.2rem;
    min-height: 1rem;
    font-size: 12px;
    top: 2px;
    right: 5px;
    position: absolute;
    text-align: center;
}

.notification_ul {
    padding: 0rem;
    margin: 0rem;
}

.notification_dd {
    position: absolute;
    top: 4.5rem;
    right: 3rem;
    background-color: var(--white);
    height: auto;
    width: 350px;
    border: 1px solid var(--lightGray);
    border-radius: 3px;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.125), -10px 10px 30px rgba(0, 0, 0, 0.125);
    display: block;
    z-index: 4;
}

.notification_dd:before {
    content: "";
    position: absolute;
    top: -2rem;
    right: 1.8rem;
    border: 1rem solid;
    border-color: transparent transparent var(--white) transparent;
}

.notification_header {
    padding: 1rem;
    font-weight: 600;
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.notification_dd li .notify_icon {
    display: flex;
}

.notification_dd li {
    padding: 0.5rem;
    display: flex;
    align-items: center;
}

.notification_dd li:hover {
    background-color: var(--lightGray);
}

.notification_dd li .notify_icon .icon{
    display: inline-block;
    background: no-repeat 0 0;
}

.icon_img {
    width: 4rem;
    height: 4rem;
}

.notification_dd li .notify_data {
    margin: 0rem;
    width: 13rem;
    overflow: hidden;       /* Hide anything that overflows the box */
    white-space: nowrap;    /* Prevent text from wrapping to the next line */
    text-overflow: ellipsis;
}

.notification_dd li .notify_data_show_all {
    white-space: normal;
    font-size: 0.7rem;
    text-align: left;
}

.notification_dd li .notification_title {
    color: var(--black);
    font-weight: 600;
    font-size: 0.7rem;
}

.notification_dd li .notification_sub_title {
    font-size: 0.7rem;
}

.notification_dd li .notify_status {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: var(--blue);
}

.show_all {
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.show_all p {
    font-weight: 600;
    color: var(--blue);
    cursor: pointer;
}

.show_all a {
    text-decoration: none;
}

.show_all p:hover {
    text-decoration: underline;
}

.notification_status {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.notification_status .notify_status_time {
    font-size: 0.5rem;
    color: #523936;
    padding: 0rem 0rem 0rem 0.5rem;
}

.readAll {
    color: var(--lightGray);
    cursor: pointer;
}

.notify_show_more {
    color: var(--gray);
    font-size: 0.5rem;
    cursor: pointer;
}

.notify_status {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: var(--blue);
}

.more_options {
    cursor: pointer;
    min-width: 10rem;
}

.css-maozd2-MuiTypography-root-MuiLink-root {
    text-decoration: none !important;
}

.acc-logo {
    display: flex;
}

.logo{ 
    cursor: pointer;
}

.burger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.burger-icon {
    display:"flex";
    align-items:"center";
}

.notification-container {
    display: flex;
    flex-direction: column;
    width: calc(100vw - 130px);
    max-width: 100vw;
    justify-content: center;
    align-items: flex-start;
}

.notification-selection {
    padding: 1rem;
    background-color: #f6f6f6;
    border-radius: 1rem;
    margin: 1rem 0rem;
    width: 100%;
    max-width: 100%;
}

.notification-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    padding: 1rem 0rem;
}

.text {
    text-align: left;
    white-space: normal;
    word-break: break-word;
    width: 95%;
}

.more {
    cursor: pointer;
}

.notification-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile_dd {
    position: absolute;
    top: 65px;
    right: 2rem;
    background-color: #f8f9fa;
    height: auto;
    min-width: 250px;
    border: 1px solid var(--lightGray);
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    display: block;
    padding: 15px;
    z-index: 1000;
}

.dashboard {
    display: flex;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.content-container {
    margin-top: 60px; /* Same height as the navbar */
    margin-left: 80px; /* Same width as sidebar */
    padding: 20px;
    height: calc(100vh - 60px);
    overflow-y: auto;
}

.listMachines {
    width: 90%;
}

.machineInfo {
    min-width: 80%;
    max-width: 90vw;
}

.MuiList-root .MuiMenuItem-root {
    white-space: normal !important;
    word-break: break-word;
    display: block;
    margin: 0px 5px 5px;
}

@media (max-width: 1023px) {
    .content-container {
        margin-left: 0px;
    }
    .listMachines {
        padding-left: 80px;
    }
    .machineInfo {
        padding-left: 80px;
    }
}

@media (max-width: 768px) {
    .content-container {
        margin-left: 0px;
    }
    .account-div {
        width: 80%;
    }
    .logo {
        width: 60%;
    }
    .burger {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .lblUser {
        display: none;
    }
    .hk-navbar {
        width: 100%;
    }
    .burger-icon {
        padding: 0px;
    }
    .notification_dd {
        top: 5rem;
        right: 4rem;
    }
    .profile_dd {
        top: 4.5rem;
        left: 25rem;
    }
    .listMachines {
        padding-left: 0;
    }
}

@media (max-width: 480px) {
    .notification_dd {
        max-width: 15rem;
        z-index: 10;
    }
    .notification_header .readAll {
        font-size: 0.5rem;
    }
    .profile_dd {
        position: absolute;
        max-width: 15rem;
        left: 11rem;
        right: 0;
        z-index: 1000;
    }
}

@media (max-width: 320px) {
    .profile_dd {
        position: absolute;
        max-width: 15rem;
        left: 1rem;
        right: 0;
        z-index: 1000;
    }
}