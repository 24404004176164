.salesChartDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tab-content {
    min-width: 90%;
}

.lblSalesChart {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;
}

.chart-wrapper {
    width: 100%;
    max-width: 100%;
    height: auto;
    min-height: 300px;
}

@media (max-width:1023px) {
    .tab-content {
        min-width: 70%;
    }
    .salesChartDiv {
        width: 100%;
    }
    .chart-wrapper {
        width: 100%;
        height: 300px;
    }
}

@media (max-width: 768px) {
    /* .salesChartDiv {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    } */
    .chart-wrapper {
        width: 100%;
        height: 250px;
    }
}

@media (max-width: 480px) {
    .chart-wrapper {
        width: 100%;
        height: 200px;
    }
}