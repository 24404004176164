.realtimeArea {
    width: 100%;
    padding: 0rem 1rem;
}

.lblRealtime {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: bold;
    font-size: 30px;
}

.overallDiv {
    margin: 30px 0px;
    max-width: 100%;
}

.latest-trans-div {
    margin-top: 15px;
    /* max-width: 80%; */
}